<template>
    <div>
        <router-view v-show="this.$route.matched.length==3"></router-view>
        <PageHeaderLayout v-show="this.$route.matched.length==2">
            <div class="main-page-content">
              <div style="min-height: calc(100vh - 100px);">
                <el-form :inline="true" ref="form">
                  <el-form-item>
                    <ArtistSearch @handleSelect="chooseAdArtist"/>
                  </el-form-item>
                  <el-form-item label="消息状态：">
                    <el-checkbox-group @change="chooseStatus" v-model="searchStatus.msg_status">
                      <el-checkbox :label="2">已发送</el-checkbox>
                      <el-checkbox :label="1">未发送</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item label="审核状态：">
                    <el-select @change="chooseaudit" placeholder="全部" v-model="searchStatus.audit">
                      <el-option
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                          v-for="item in statusDatas">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-date-picker
                        :picker-options="pickerOptions"
                        @change="handlerQuery"
                        align="right"
                        end-placeholder="结束月份"
                        range-separator="至"
                        start-placeholder="开始月份"
                        type="monthrange"
                        unlink-panels
                        v-model="searchStatus.rangYm" value-format="yyyy-MM">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="handlerQuery" icon="el-icon-search" type="primary">查询</el-button>
                  </el-form-item>
                  <el-form-item style="margin-left: 20px"
                                v-if="userPermissions.indexOf('artist_divide_import') !== -1">
                    <el-upload
                        :before-upload="beforeUpload"
                        :file-list="file"
                        :headers="uploadHeaders"
                        :on-change="handleChange"
                        :on-success="importSuccess"
                        :show-file-list="false"
                        action="/admin_api/artist_divide/import"
                        class="upload-demo"
                        multiple
                        name="divide">
                      <el-button icon="el-icon-upload" type="primary">导入分成数据</el-button>
                    </el-upload>
                  </el-form-item>
                  <el-form-item v-if="userPermissions.indexOf('artist_divide_import') !== -1">
                    <el-button @click="downLoadTemplate('divide')" icon="el-icon-download" type="info">下载分成模板
                    </el-button>
                  </el-form-item>
                  <el-form-item style="margin-left: 20px"
                                v-if="userPermissions.indexOf('artist_order_import') !== -1">
                    <el-upload
                        :before-upload="beforeUpload"
                        :file-list="file"
                        :headers="uploadHeaders"
                        :on-change="handleChange"
                        :on-success="importSuccess"
                        :show-file-list="false"
                        action="/admin_api/artist_order/import"
                        class="upload-demo"
                        multiple
                        name="order">
                      <el-button icon="el-icon-upload" type="primary">导入订单明细</el-button>
                    </el-upload>
                  </el-form-item>
                  <el-form-item v-if="userPermissions.indexOf('artist_order_import') !== -1">
                    <el-button @click="downLoadTemplate('order')" icon="el-icon-download" type="info">下载订单模板
                    </el-button>
                  </el-form-item>
                </el-form>
                <div class="default-table">
                  <el-table
                      :border="true"
                      :data="tableData"
                      :header-cell-style="headClass"
                      @selection-change="handleSelectionChange"
                      @sort-change="changeTableSort"
                      ref="multipleTable"
                      row-key="id"
                      style="width: 100%"
                      tooltip-effect="dark"
                  >

                    <el-table-column
                        :reserve-selection="true"
                        key="selectable"
                        type="selection"
                        width="40">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="序号"
                        width="50"
                    >
                      <template slot-scope="scope">
                        <span>{{ tablePagination.offset + scope.$index + 1 }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="创建时间"
                        min-width="100"
                        prop="created_at"
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="月份"
                        min-width="100"
                        prop="date_time"
                        sortable="custom"
                    >
                    </el-table-column>
                    <el-table-column align="center" label="红人昵称" min-width="120" prop="artist_id"
                                     show-overflow-tooltip
                                     sortable="custom"
                    >
                      <template slot-scope="{row}">
                        <artist-nickname :info="{artist_id:row.artist_id,nickname:row.nickname}"/>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="推广应付款"
                        prop="publicity_pay"
                        sortable="custom"
                    >
                      <template slot-scope={row}>
                        <span>{{ moneyFormat(row.publicity_pay) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="电商应付款"
                        prop="commerce_pay"
                        sortable="custom"
                    >
                      <template slot-scope={row}>
                        <span>{{ moneyFormat(row.commerce_pay) }}</span>
                      </template>

                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="佣金应付款"
                        prop="brokerage_pay"
                        sortable="custom"
                    >
                      <template slot-scope={row}>
                        <span>{{ moneyFormat(row.brokerage_pay) }}</span>
                      </template>

                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="其他应扣/补款"
                        prop="other_pay"
                        sortable="custom"
                    >
                      <template slot-scope={row}>
                        <span>{{ moneyFormat(row.other_pay) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="应付款合计"
                        prop="due_total"
                        sortable="custom"
                    >
                      <template slot-scope={row}>
                        <span>{{ moneyFormat(row.due_total) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="个税"
                        prop="person_tax"
                        sortable="custom"
                    >
                      <template slot-scope={row}>
                        <span>{{ moneyFormat(row.person_tax) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="right"
                        label="实付款"
                        prop="actual_pay"
                        sortable="custom"
                    >
                      <template slot-scope={row}>
                        <span>{{ moneyFormat(row.actual_pay) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="扣（补款）备注"
                        prop="deduct_remark"
                        width="120"
                    >

                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="组名"
                        prop="group_name"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        align="center"
                        label="组长"
                        prop="group_leaders"
                        show-overflow-tooltip
                        width="120"
                    >
                      <template slot-scope={row}>
                        <span>{{ row.group_leaders.length > 0 ? row.group_leaders.toString() : '' }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                        :filter-method="filterstatus"
                        :filters="[
                                  { text: '未发送', value: 1 }, { text: '已发送', value: 2 },
                                 ]"
                        align="center"
                        label="消息状态"
                        prop="msg_status"
                    >
                      <template slot-scope="scope">
                        <el-tag :style="scope.row.msg_status === 1?'':'border-color:#409EFF;color:#409EFF'"
                                :type="scope.row.msg_status === 1? 'primary' : 'success'"
                                disable-transitions
                                effect="plain">
                          {{ scope.row.msg_status === 1 ? '未发送' : '已发送' }}
                        </el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="消息发送时间"
                        prop="msg_send_at"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="审核状态"
                        prop="audit"
                        width="100"
                    >
                      <template slot-scope="scope">
                        <el-tag :type="scope.row.audit === 1||  scope.row.audit === 5 ? 'success':'primary'"
                                disable-transitions
                                effect="plain">
                          {{ scope.row.audit ? statusData[scope.row.audit] : '未知状态' }}

                        </el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="操作"
                        width="100">
                      <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)"
                                   key="artist_divide_item"
                                   size="small" type="text"
                                   v-if="userPermissions.indexOf('artist_divide_item') !== -1">查看/修改
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        key="artist_divide_notify" label="通知"
                        v-if="userPermissions.indexOf('artist_divide_notify') !== -1" width="80"
                    >
                      <template slot-scope="scope">
                        <el-button @click="noticeClick(scope.row.id)" size="small" style="color: #409EFF"
                                   type="text"><i
                            class="el-icon-chat-line-square"></i> 通知
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        key="artist_divide_check"
                        label="审核"
                        prop="examine"
                        v-if="userPermissions.indexOf('artist_divide_check') !== -1"
                        width="120"
                    >
                      <template slot-scope="scope">
                        <el-button @click="examineClick(scope.row)" size="small" style="color: #42b983"
                                   type="text" v-if="scope.row.audit===3">
                          <i class="el-icon-check"></i>通过
                        </el-button>
                        <el-button @click="examineClick(scope.row,'close')" size="small"
                                   type="text"
                                   v-if="scope.row.audit!=4&&scope.row.audit!=5&&scope.row.audit!=6">
                          <i class="el-icon-close"></i>
                          关闭
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-row justify="right" type="flex">
                    <el-col :span="2">
                      <el-button :disabled="selection.length=== 0"
                                 @click="batchNotifyClick"
                                 style="margin-top: 12px;"
                                 type="warning"
                                 v-if="userPermissions.indexOf('artist_divide_notify') !== -1">
                        <i class="el-icon-chat-line-square"></i> 批量通知
                      </el-button>
                    </el-col>
                    <el-col :span="10">
                      <el-button
                          :disabled="statusDivideBtn"
                          @click="batchExamineClick"
                          style="margin-top: 12px" type="primary"
                          v-if="userPermissions.indexOf('artist_divide_check') !== -1">
                        批量通过审核
                      </el-button>
                    </el-col>
                    <el-col :span="12">
                      <Pagination
                          :limit.sync="tablePagination.page_size"
                          :page.sync="tablePagination.current_page"
                          :total="tablePagination.total"
                          @pagination="getList"/>
                    </el-col>
                  </el-row>
                  <el-row>
                    <h3>合计：应付款：<span style="color: #ff3176;font-style:italic ">{{ total.due_total }}</span> 元，实付款<span
                        style="color: #ff3176;font-style:italic "> {{ total.actual_pay }}</span> 元</h3>
                    <el-button @click="clearSelect" style="margin-top: 12px" type="primary">
                      清空选择
                    </el-button>
                  </el-row>
                </div>
                <DingTalkNotify :dataJson="JSON.stringify(dialogData)" @close="closefn"
                ></DingTalkNotify>
                <el-dialog :visible.sync="dialogVisible" title="提示" width="30%">
                  确认批量通知：
                  <br/>
                  <div :key="index+item.nickname" style="font-size: 16px" v-for="(item,index) in msgname">
                    <br/>
                    <span style="color: #ff3176;">{{ item.group_name }}</span> 的 <span
                      style="color: #ff3176;">{{ item.nickname }} </span>
                    的组长： <span style="color:#ff3176 ">
                        {{item.group_leaders}}
                    </span>
                  </div>
                  <span class="dialog-footer" slot="footer">
                  <el-button @click="(()=>{this.$notify.info('已取消')
                  dialogVisible = false })">取 消</el-button>
              <el-button @click="surebtnNotify" type="primary" v-loading="btnloading">确 定</el-button>
                    </span>
                </el-dialog>
              </div>

            </div>
        </PageHeaderLayout>
    </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import {mapGetters} from 'vuex'
import {getToken} from '@/utils/auth'
import FileSaver from 'file-saver'
import ArtistSearch from '@/components/artist/ArtistSearch'
import DingTalkNotify from '@/components/dingTalkNotify/index'

export default {
    name: 'PublicationIndex',
    components: { PageHeaderLayout, ArtistSearch, DingTalkNotify },
    computed: {
      ...mapGetters(['userPermissions', 'userInfo']),
      statusData() {
        const map = {}
        this.statusDatas.forEach((item) => {
          map[item.value] = item.text
        })
        return map
      },
      statusDivideBtn() {
        let returnValue
        (this.searchStatus.audit != 3 || this.selection.length === 0) ? returnValue = true : returnValue = false
        return returnValue
      }
    },
    data() {
      return {
        uploadHeaders: { 'HYPERF-SESSION-ID': null },
        file: [],
        //筛选data
        searchStatus: {
          artist_id: [],
          msg_status: [],
          audit: [],
          rangYm: []
          // start_month: '',
          // end_month: ''
        },
        // 数组data
        tableData: [],
        //数组页码
        tablePagination: {
          total: 0,
          page_size: 15,
          offset: 0,
          current_page: 1
        },
        selection: [],
        //状态筛选
        statusDatas: [
          { text: '全部', value: 0 },
          { text: '待确认', value: 1 },
          { text: '取消确认', value: 2 },
          { text: '待审核', value: 3 },
          { text: '审核未通过', value: 4 },
          { text: '已审核', value: 5 },
          { text: '审核关闭', value: 6 }
        ],
        // rangYm: null,
        pickerOptions: {
          shortcuts: [{
            text: '本月',
            onClick(picker) {
              picker.$emit('pick', [new Date(), new Date()])
            }
          }, {
            text: '今年至今',
            onClick(picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 6)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        searchCondition: {
          platform_code: null,
          artist_id: null,
          time_value: []
        },
        checkList: [],
        // , checkLists: [],
        dialogData: {
          dialogVisible: false,
          head: {
            id: '',
            type: ''
          },
          api: 'dingTalkNotifyartistDivide'
        },
        orderSort: { 'id': 'asc' },
        msgname: {},
        dialogVisible: false,
        total: {
          actual_pay: 0,//实付款合计
          due_total: 0 //应付款合计
        },
        btnloading: false
      }
    },
    methods: {
      noticeClick(vale) {
        //通知按钮
        this.dialogData.dialogVisible = true
        this.dialogData.head.id = vale
        this.dialogData.head.type = 'assistant'
      },

      closefn(data) {
        this.dialogData = data
        this.getList()
      },
      clearSelect() {
        this.$refs.multipleTable.clearSelection()
      },
      // slotlabel(h) {
      //   return h(
      //     'div',
      //     [
      //       h('el-checkbox', {
      //         attrs: {
      //           id: 'foo'
      //         },
      //         on: {
      //           change: this.checkAll // 全选事件
      //         }
      //       })
      //     ]
      //   )
      // },
      // checkRow(val) {
      //   if (this.checkList.indexOf(val) != -1) {
      //     this.checkList.splice(this.checkList.indexOf(val), 1)
      //   } else {
      //     let arr2 = []
      //     this.checkList.indexOf(val)
      //     this.checkList.push(val)
      //     let s1 = new Set(this.checkList)
      //     for (let item of s1) {
      //       arr2.push(item)
      //     }
      //     this.checkLists = arr2
      //   }
      // },
      // checkAll(val) {
      //   if (val) {
      //     this.checkList = []
      //     this.tableData.forEach((item) => {
      //       this.checkList.push(item.id)
      //       this.checkLists.push(item.id)
      //       this.msgname.push(item.group_leaders)
      //     })
      //   } else {
      //     this.checkList = []
      //     this.checkLists = []
      //   }
      // },
      batchNotifyClick() {
        if (this.selection.length <= 0) {
          this.$notify.error('请选择数据')
        } else {
          this.selection.forEach((item, index) => {
            this.checkList.push(item.id)
            this.msgname[index] = {
              group_leaders: item.group_leaders.toString().replaceAll(',,', ''),
              nickname: item.nickname,
              group_name: item.group_name
            }
          })
          this.dialogVisible = true
        }
      },
      async surebtnNotify() {
        this.btnloading = true
        let info = await this.$api.batchNotifysend({ ids: this.checkList })
        if (info) {
          this.btnloading = false
          this.$notify.success('发送成功')
          this.dialogVisible = false
          this.getList()
        }
      },
      chooseAdArtist(item) {
        JSON.stringify(item) === '{}' ? this.searchStatus.artist_id = [] : this.searchStatus.artist_id = [item.id]
        this.handlerQuery()
      },
      chooseaudit(item) {
        item === 0 ? this.searchStatus.audit = [] : this.searchStatus.audit = item
        this.handlerQuery()
      },
      chooseStatus(item) {
        this.searchStatus.msg_status = item
        this.handlerQuery()
      },


      //下载上传模块
      handleChange(file) {
        this.file = [file]
      },
      importSuccess(response) {
        if (response.code === 200) {
          this.$notify.success('上传成功')
          this.submitted = []
          this.loadingSubmit = false
          this.showProgress = false
          this.dataList = response.data.list || []
          this.getList()
        } else {
          this.$notify.warning(response.msg)
        }
      },
      beforeUpload() {
        this.uploadLoading = true
        this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
      },


      async downLoadTemplate(type) {
        try {
          var isFileSaverSupported = !!new Blob
          if (isFileSaverSupported) {
            // let searchCondition = this.handleSearchCondition()
            let response = await this.$api.downLoadTemplateNotify({ type: type })
            let data = response.data
            let contentType = response.headers['content-type']
            let name = type === 'divide' ? '分成数据模板' : '订单明细模板'
            let blob = new Blob([data], {
              type: contentType
            })
            FileSaver.saveAs(blob, decodeURI(name + '.xlsx'), { autoBom: true })
          } else {
            this.$message.warning('浏览器不支持导出文件')
          }
        } catch (e) {
          this.$message.warning('导出异常，请联系管理员')
          // alert(JSON.stringify(e))
        }
      },

      handleClick(vaid) {
        //操作按钮
        //查看
        this.$router.push({
          name: 'artist_commission_detail',
          params: { id: vaid.id }
        })
      },


      examineClick(vaid, type) {
        let msg = type === 'close' ? '是否关闭该审核' : '是否确认通过审核'
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = { 'id': vaid.id }
          data.audit = type === 'close' ? '6' : '5'
          this.auditSubmit(data)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },
      handleSelectionChange(val) {
        this.selection = val
        this.total.actual_pay = 0
        this.total.due_total = 0
        this.selection.forEach((item) => {
          this.total.actual_pay += Number(item.actual_pay)
          this.total.due_total += Number(item.due_total)
        })
      },
      batchExamineClick() {
        let ids = []
        this.selection.forEach((item) => {
          ids.push(item.id)
        })
        if (ids.length != 0) {
          this.$confirm('是否批量通过审核', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            let id = await this.$api.batchartistAudit({ ids, 'audit': 5 })
            if (id) {
              this.$notify.success('审核通过')

              this.getList()
            } else this.$notify.error('未知失败')
          }).catch(() => {
            this.$notify.info('已取消')

          })
        } else this.$notify.error('请选择数据')

      },
      async auditSubmit(data) {
        let id = await this.$api.artistAudit(data)
        if (id) {
          this.$notify.success('成功')
          this.getList()
        } else this.$notify.error('未知失败')
      },

      filterstatus(value, row) {
        return row.msg_status === value
      },
      handleSearchCondition() {
        let cond = {}
        if (this.searchStatus.msg_status.length != 0) {
          cond['msg_status'] = this.searchStatus.msg_status
        }
        if (this.searchStatus.audit.length != 0) {
          cond['audit'] = [this.searchStatus.audit]
        }
        if (this.searchStatus.artist_id.length != 0) {
          cond['artist_id'] = this.searchStatus.artist_id
        }
        if (this.searchStatus.rangYm && this.searchStatus.rangYm.length == 2) {
          cond['start_month'] = this.searchStatus.rangYm[0]
          cond['end_month'] = this.searchStatus.rangYm[1]
        }

        return cond
      },
      handlerQuery() {
        this.tablePagination.current_page = 1
        this.getList()
      },

      changeTableSort({ prop, order }) {
        let orderSort = {}
        if (order) {
          orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
        }
        this.orderSort = orderSort
        this.getList()
      },
      async getList() {
        // this.tableData = []
        let searchCondition = this.handleSearchCondition()

        Object.assign(searchCondition, {
          page_size: this.tablePagination.page_size,
          current_page: this.tablePagination.current_page
        }, { order: this.orderSort })
        let { list, pages } = await this.$api.getArtistDivideList(searchCondition)
        this.$nextTick(() => {
          this.tableData = list
          this.tablePagination = pages
          this.tablePagination.current_page = pages.current_page || 1
          this.tablePagination.page_size = pages.page_size || 1
          this.tablePagination.offset = pages.offset || 0
          this.tablePagination.total = pages.total
          // let checkall = document.getElementById('foo')
          // if (checkall != null) {
          //   checkall.childNodes[0].classList.remove('is-checked')
          //   checkall.classList.remove('is-checked')
          // }
          // this.checkAll()
        })
      },    //批量样式居中
      headClass() {
        return 'text-align:center'
      },
      moneyFormat(value) {
        return this.$utils.numberFormat(value, 2, '.', ',', 'round')
      }

    },
    watch: {
      '$route.matched': {
        immediate: true,
        handler(n) {
          if (n.length === 2) {
            this.getList()
          }
        }
      }

    }
  }
</script>
<style lang="scss">
    #foo > .el-checkbox__input.is-checked .el-checkbox__inner, .foo > * > .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #409EFF;
        border: 1px solid #409EFF;

    }

    #foo > * > .el-checkbox__inner:hover, .foo > * > * > .el-checkbox__inner:hover {
        border-color: #409EFF !important;
    }

    .foo > * > span:last-child {
        display: none;
    }
</style>